@import "minima";

:root {
  --max-width: 1024px;
  --img-padding: 60px;
  --home-max-width: 1200px;
}

@media (max-width: 650px) {
  :root {
    --max-width: 570px;
    --img-padding: 10px !important;
    --home-max-width: 650px;
  }

  main .wrapper, footer .wrapper {
    .home {
      ul { 
        display: block !important;
        li {
          img {
            width: 100% !important;
            height: calc(var(--home-max-width) / 3) !important;
          }
        }
      }
    }
  }

  img {
    border: none !important;
  }
}

@media (max-width: 1150px) {
  :root {
    --max-width: 900px;
    --img-padding: 30px;
    --home-max-width: 1150px;
  }
}

main .wrapper, footer .wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: calc(300px + var(--max-width)) !important;

  article, p {
    max-width: var(--max-width);
  }

  .home {
    max-width: var(--home-max-width);

    ul {
      display: grid;
      grid-template-columns: auto auto auto;

      li {
        margin: 8px;
        img {
          object-fit: cover;
          object-position: center; /* Center the image within the element */
          width: calc(var(--home-max-width) / 3);
          height: calc((var(--home-max-width) / 3)/1.5);
        }
      }
    }
  }
}

img, a img {
  max-width: 100%;
  border: solid thin #ccc;
  margin-left: calc(-1 * var(--img-padding));
  padding: var(--img-padding);
}

img.title-img {
  width: 100%;
  border: none;
  margin: unset;
  padding: unset;
}

a + a {
  img {
    margin-top: 15px;
  }
}

img + img, a img + a img {
  margin-top: 15px;
}

figure {
  margin-bottom: 15px;
}

figcaption {
  text-align: center;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header .wrapper {
  .site-tile { float: left; }
  .site-nave { float: right; }
  max-width: var(--home-max-width) !important;
}

footer .wrapper {
  display: block !important;
  max-width: var(--home-max-width) !important;
  .footer-col-wrapper {
    display: flex;
    align-items: space-between;
    .footer-col-1, .footer-col-2, .footer-col-3 {
      width: calc(var(--home-max-width) / 3) !important;
    }
    .footer-col-2 {
      text-align: center;
    }
    .footer-col-3 {
      text-align: right;
    }
  }
}
